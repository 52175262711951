<template>
  <div class="login-box">
    <div class="header">
      <div class="title">
        <img class="onion" src="@/assets/svg/onion-icon.svg" alt="" />
        <img src="@/assets/svg/onion-text.svg" alt="" />
      </div>
      <div @click="closeLogin" class="close">
        <img src="@/assets/svg/close.svg" alt="" />
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="app-img">
          <div class="appcode">
            <img v-if="!codeInvalid" :src="qrcodeImg" alt="" />
          </div>
          <img src="@/assets/svg/app.svg" alt="" />
        </div>
        <div class="code-invalid" @click="getScanCode" v-if="codeInvalid">
          二维码已失效，点击刷新
        </div>
        <div
          style="
            margin: 15px 0 0;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
          "
        >
          请使用洋葱韩语APP扫码登录
        </div>
        <div
          class="download-app"
          @mouseenter="mouseEnter"
          @mouseleave="mouseLeave"
        >
          <img src="@/assets/svg/onion-icon.svg" alt="" />
          下载APP
        </div>
        <div
          class="download-app-code"
          v-show="appCode"
          @mouseenter="mouseEnter"
          @mouseleave="mouseLeave"
        >
          <div class="content-code">
            <img src="@/assets/svg/appcode.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">手机号登录</div>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item prop="phone">
            <el-input
              clearable
              placeholder="输入手机号"
              v-model="form.phone"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              clearable
              placeholder="输入验证码"
              v-model="form.code"
            ></el-input>
            <el-button type="text" @click="sendCode">发送验证码</el-button>
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <el-button
            :type="loginFlag ? 'primary' : 'info'"
            :disabled="!loginFlag"
            @click="handleLogin"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getScanCode, getScanCodeStatus } from "@/api/login";
import QRCode from "qrcode"; // 生成二维码
export default {
  props: {
    form: {
      type: Object,
    },
  },
  computed: {
    loginFlag() {
      return this.form.phone && this.form.code;
    },
  },
  data() {
    let phone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号码格式错误"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        phone: [{ validator: phone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
      },
      appCode: false,
      timeOut: null,
      loginId: "",
      time: null,
      qrcodeImg: "",
      codeInvalid: false,
    };
  },
  created() {
    this.getScanCode();
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
  methods: {
    getScanCode() {
      getScanCode().then((res) => {
        if (res.code !== "SUCCESS") return;
        this.loginId = res.data;
        QRCode.toDataURL(`OnionKorean_ScanLoginCode=${this.loginId}`, {
          margin: 0,
        }).then((res) => {
          this.qrcodeImg = res;
        });
        this.codeInvalid = false;
        this.getScanCodeStatus();
      });
    },
    //
    getScanCodeStatus() {
      this.time = setInterval(() => {
        getScanCodeStatus({ scanLoginCode: this.loginId }).then((res) => {
          if (res.code !== "SUCCESS") {
            clearInterval(this.time);
            return this.$message.error(res.message);
          }
          if (res.data == -1) {
            clearInterval(this.time);
            this.codeInvalid = true;
          }
          if (res.data != -1 && res.data != 0) {
            clearInterval(this.time);
            this.$emit("scanLogin", res.data);
          }
        });
      }, 1000);
    },
    sendCode() {
      this.$emit("getCode");
    },
    handleLogin() {
      this.$emit("loginByCode");
    },
    closeLogin() {
      this.$emit("closeLogin");
    },
    mouseEnter() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
        this.timeOut = null;
      } else {
        this.appCode = true;
      }
    },
    mouseLeave() {
      this.timeOut = setTimeout(() => {
        this.appCode = false;
        clearTimeout(this.timeOut);
        this.timeOut = null;
      }, 200);
    },
  },
};
</script>

<style scoped lang="scss">
.login-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 672px;
  height: 346px;
  background: #ffffff;
  box-shadow: 0px -3px 10px -2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.header {
  padding: 0 12px 0 38px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  .title {
    display: flex;
    align-items: center;
    .onion {
      margin-right: 7px;
      width: 26px;
      height: 26px;
    }
    img {
      width: 54px;
      height: 20px;
    }
  }
  .close {
    cursor: pointer;
    img {
      display: block;
    }
  }
}
.content {
  margin: 12px 0 0;
  padding: 0 38px;
  box-sizing: border-box;
  display: flex;
}
.left {
  position: relative;
  padding: 0 31px 0 0;
  width: 257px;
  border-right: 1px solid #e2e2e2;
  .app-img {
    display: flex;
    .appcode {
      position: relative;
      margin: 0 21px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 117px;
      height: 117px;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      img {
        width: 110px;
        height: 110px;
      }
    }
    img {
      width: 119px;
      height: 125px;
    }
  }
  .code-invalid {
    text-align: center;
    color: gray;
    cursor: pointer;
  }
  .download-app {
    margin: 22px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 36px;
    background: #f8f8f9;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    color: #5a85ff;
    cursor: pointer;
    img {
      margin-right: 7px;
      width: 22px;
      height: 22px;
    }
  }
  .download-app-code {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 263px;
    height: 199px;
    left: 0px;
    top: -20px;
    background: #ffffff;
    box-shadow: 0px 1px 14px -5px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    .content-code {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 145px;
      height: 145px;
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      border-radius: 6px;
      img {
        width: 128px;
        height: 127px;
      }
    }
  }
}
.right {
  padding-left: 31px;
  box-sizing: border-box;
  .title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
  }
  .el-form {
    width: 276px;
  }
  .el-form-item {
    margin-bottom: 14px;
  }
  ::v-deep .el-form-item__error {
    margin-left: -6px;
    transform: scale(0.9);
  }
  ::v-deep .el-form-item__content {
    display: flex;
    border-bottom: 1px solid #eeeeee;
  }
  ::v-deep .el-input__inner {
    padding: 0px;
    border: none;
    :nth-child(1) {
      border-bottom: 1px solid #eeeeee;
    }
    &::placeholder {
      color: #939393;
    }
  }
  .el-button--info,
  .el-button--primary {
    margin: 18px auto 0;
    width: 170px;
  }
  .el-button--text {
    color: #333333;
  }
}
</style>

