import api from "@/utils/request";
const loginUrl = process.env.VUE_APP_LOGIN_API_URL
// 登录
export function loginByCode(data) {
  return api({
    method: "post",
    url: `${loginUrl}/phone/login/loginByCode`,
    data
  });
}

// 获取验证码
export function getCode(data) {
  return api({
    method: "post",
    url: `${loginUrl}/phone/login/getCode`,
    data
  });
}

// 退出登录
export function loginOut(data) {
  return api({
    method: "post",
    url: `/app/login/loginOut`,
    data
  });
}

// 获取扫一扫id
export function getScanCode() {
  return api({
    method: "get",
    url: `${loginUrl}/phone/login/getScanCode`,
  });
}

// 获取扫一扫 状态
export function getScanCodeStatus(params) {
  return api({
    method: "get",
    url: `${loginUrl}/phone/login/getScanCodeStatus`,
    params
  });
}

// 获取用户信息
export function getUserInfo() {
  return api({
    method: "get",
    url: `/app/user/getUserInfo`,
  });
}