import api from "@/utils/request";
// 获取课程列表
export function getMyCourse() {
  return api({
    method: "get",
    url: `/course/getMyCourse`,
  });
}

// 获取课程详情
export function getCourseInfo(params) {
  return api({
    method: "get",
    url: `/course/getCourseInfo`,
    params
  });
}

// 获取章节列表
export function getMyCourseChapters(params) {
  return api({
    method: "get",
    url: `/course/getMyCourseChapters`,
    params
  });
}

// 获取章节详情
export function chapterDetail(params) {
  return api({
    method: "get",
    url: `course/chapterDetail`,
    params
  });
}

// 获取老师二维码
export function getTeacherCode() {
  return api({
    method: "get",
    url: `/course/getTeacherCode`,
  });
}