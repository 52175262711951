<template>
  <div class="video-box">
    <video
      v-show="chapterDetailData.videoUrl"
      id="myVideo"
      ref="myVideoRef"
      controls="controls"
      preload="auto"
      autoplay
      controlslist="nodownload"
      :src="chapterDetailData.videoUrl"
      @timeupdate="timeupdate"
      @play="play"
      @contextmenu="contextmenu"
    >
      浏览器不支持播放视频
    </video>
    <div class="not-vip" v-if="!vipVisible">
      <div class="wx-code">
        <div class="text">内容为VIP课程内容，请先购买课程</div>
        <img :src="teacherWxCode" alt="" />
      </div>
    </div>
    <div
      class="play-complete"
      v-if="isPalyComplete && chapterDetailData.videoUrl"
    >
      <div class="content">
        <div class="text">已看完，去洋葱韩语APP提交作业可解锁下一课课程</div>
        <div class="footer">
          <el-button @click="toReplay" icon="el-icon-refresh-right"
            >重 播</el-button
          >
        </div>
      </div>
    </div>
    <div class="next-box" v-if="isNextChapter">
      <div class="content">
        <div class="text">此章节已播完,即将播放下一章节</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 是否是vip
    vipVisible: {
      type: Boolean,
    },
    // 视频是否播完
    isPalyComplete: {
      type: Boolean,
    },
    // 是否播放下一章节
    isNextChapter: {
      type: Boolean,
    },
    // 章节详情
    chapterDetailData: {
      type: Object,
    },
    teacherWxCode: {
      type: String,
    },
  },
  data() {
    return {};
  },
  created() {
    
  },
  mounted(){
  },
  methods: {
    contextmenu(event) {
      event.preventDefault();
    },
    timeupdate() {
      if (
        document.getElementById("myVideo").currentTime ==
        this.chapterDetailData.videoTime
      ) {
        this.$emit("palyComplete");
      }
    },
    toReplay() {
      this.$emit("toReplay");
    },
    // 播放 视频
    play() {
      this.$emit("videoPlay");
    },
  },
};
</script>

<style scoped lang="scss">
.video-box {
  position: relative;
  height: calc(100% - 60px);
  width: 100%;
}
video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.not-vip {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #191919;
  .wx-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 505px;
    height: 310px;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 8px;
    img {
      max-width: 178px;
      max-height: 178px;
    }
    .text {
      margin: 32px 0 42px;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
    }
  }
}
.play-complete {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #191919;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 505px;
    height: 310px;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 8px;
    .text {
      font-size: 16px;
      color: #ffffff;
    }
    .footer {
      .el-button {
        width: 90px;
      }
    }
  }
}
.next-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: #191919;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 505px;
    height: 310px;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 8px;
    .text {
      font-size: 16px;
      color: #ffffff;
    }
  }
}
</style>
