//axios的封装
//1.引入 axios
import axios from 'axios'
import cookie from "vue-cookie"
import { Message } from 'element-ui'
// import router from '../router/index'
//2.创建axios 实例
const api = axios.create({
  //3.配置
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10 * 60 * 1000, //1分钟
})

//4. 请求拦截
api.interceptors.request.use(
  (config) => {
    //配置 请求拦截中的token
    let token = cookie.get("token");
    // console.log(token)
    if (token) {
      config.headers.token = token //后期需要改写为 后台传过来的token的值
      // config.header('Access-Control-Max-Age: 3600')
    }
    return config
  },
  (error) => {
    // console.log(error, 'console.error();')
    return Promise.reject(error)
  }
)

//5.响应拦截
api.interceptors.response.use(
  response => {
    const res = response.data;
    // console.log(res, "11111111")
    if (res.code != "SUCCESS" && res.code !== "BIZ_ERROR") {
      return Promise.reject(res);
    } else if (res.code === "BIZ_ERROR" && res.message === "未登录") {
      Message.error('登录过期,请重新登录')
      cookie.delete("token")
      window.location.reload();
      return Promise.reject(res);
    }
    return res;
  },
  (err) => {
    return Promise.reject(err)
  }
)

//6.导出
export default api
