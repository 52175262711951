<template>
  <div class="header">
    <div class="left">
      <img src="@/assets/svg/onion-icon.svg" alt="" />
      <img src="@/assets/svg/onion-text.svg" alt="" />
    </div>
    <div class="right">
      <el-popover placement="bottom" width="108" trigger="hover">
        <div class="download-code-header">
          <img src="@/assets/svg/appcode.svg" alt="" />
          扫码下载APP
        </div>
        <div slot="reference" class="down-load">
          <img src="@/assets/svg/download.svg" alt="" />
          <div>下载APP</div>
        </div>
      </el-popover>
      <el-dropdown v-if="isLogin && userInfo.userId" @command="command">
        <div class="user-info el-dropdown-link">
          <img
            :src="
              userInfo.avatar
                ? userInfo.avatar
                : require('@/assets/svg/user-headerImg.svg')
            "
            alt=""
          />
          {{ userInfo.nickname ? userInfo.nickname : "" }}
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="user-info" v-else @click="handleLogin">
        <img src="@/assets/svg/user-headerImg.svg" alt="" />
        未登录
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLogin: {
      type: Boolean,
    },
    userInfo: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    handleLogin() {
      this.$emit("handleLogin");
    },
    command() {
      this.$emit("logOut");
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  padding: 0 95px 0 89px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  background: white;
  img {
    vertical-align: middle;
  }
  .left {
    img:nth-child(1) {
      margin: 0 10px 0 0;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .down-load {
      margin: 0 45px 0 0;
      text-align: center;
      color: #333;
      cursor: pointer;
      img {
        margin: 0 0 5px;
      }
    }
    .user-info {
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 0 12px 0 0;
      }
    }
  }
}
</style>
<style >
.download-code-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}
</style>
