<template>
  <div class="home-page">
    <el-container>
      <el-header style="height: 68px"
        ><Header
          :isLogin="isLogin"
          :userInfo="userInfo"
          @handleLogin="handleLogin"
          @logOut="logOut"
        ></Header
      ></el-header>
      <el-main
        ><div class="content">
          <div class="curriculum-name">
            <div class="curriculum-content-title">
              <div class="category">VIP课程</div>
              <div class="name">{{ chapterDetailData.courseName }}</div>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              height: calc(100% - 68px);
            "
          >
            <div class="curriculum-box">
              <div class="curriculum-video">
                <div class="name">{{ chapterDetailData.chapterName }}</div>
                <Video
                  :vipVisible="vipVisible"
                  :chapterDetailData="chapterDetailData"
                  :isPalyComplete="isPalyComplete"
                  :isNextChapter="isNextChapter"
                  :teacherWxCode="teacherWxCode"
                  @palyComplete="palyComplete"
                  @toReplay="toReplay"
                  @videoPlay="videoPlay"
                ></Video>
              </div>
              <div class="curriculum-list">
                <div class="curriculum-list-title">
                  <img
                    @click="back"
                    v-if="activeCourse"
                    src="@/assets/svg/back.svg"
                    alt=""
                  />
                  {{
                    courseList.length > 1 && !activeCourse
                      ? "全部课程"
                      : "课程章节"
                  }}
                </div>
                <div
                  class="curriculum-list-box"
                  v-if="courseList.length > 1 && !activeCourse"
                >
                  <div
                    class="curriculum-item"
                    v-for="(item, index) in courseList"
                    :key="item.id"
                    @click="handleCourse(item.id)"
                  >
                    <div class="curriculum-img">
                      <img
                        :src="
                          item.courseImage
                            ? item.courseImage
                            : require('@/assets/svg/curriculum-img.svg')
                        "
                        alt=""
                      />
                    </div>
                    <div class="curriculum-info">
                      <div class="name">{{ item.courseName }}</div>
                      <div class="complete">
                        <span
                          >总课时
                          <span style="color: #597cff">{{
                            item.chapterCount
                          }}</span>
                          课</span
                        >
                        <span
                          >已完成
                          <span style="color: #88d247">{{
                            item.unlockCount || 0
                          }}</span>
                          课</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chapter-box" v-else>
                  <div
                    class="chapter-item"
                    v-for="(item, index) in chapterList"
                    :key="item.id"
                    @click="handleChapter(item.id)"
                  >
                    <div class="chapter-info">
                      <img
                        v-if="!item.unlocking && item.unlock"
                        src="@/assets/svg/complete.svg"
                        alt=""
                      />
                      <img
                        v-else-if="!item.unlocking && !item.unlock"
                        src="@/assets/svg/Notunlocked.svg"
                        alt=""
                      />
                      <img
                        v-else-if="item.unlocking && !item.unlock"
                        src="@/assets/svg/Unlocking.svg"
                        alt=""
                      />
                      <div
                        class="chapter-name"
                        :class="
                          activeChapter === item.id ? 'active-chapter-name' : ''
                        "
                      >
                        {{ item.chapterName }}
                      </div>
                    </div>
                    <img
                      v-if="activeChapter === item.id"
                      class="play"
                      src="@/assets/img/videoplay.gif"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="curriculum-details">
          <div class="curriculum-details-content" v-if="chapterDetailData.id">
            <div class="title-name">课程详情</div>
            <div
              class="audio-file"
              v-for="(item, index) in chapterDetailData.audioList"
              :key="index"
              @click="handlePlayAudio(index, item.play)"
            >
              <div class="audio-tape-reading">
                <img src="@/assets/svg/onionIcon.svg" alt="" />
                <div class="audio-name">
                  <div class="source-name">{{ item.sourceName }}</div>
                  <div class="audio-time">{{ item.sourceTime }}</div>
                </div>
              </div>
              <img
                v-if="!item.play"
                class="play"
                src="@/assets/svg/audio.svg"
                alt=""
              />
              <img v-else src="@/assets/img/play.gif" alt="" />
              <audio
                id="myaudio"
                class="myaudio"
                @ended="audioEnded($event, index)"
                :src="item.sourceUrl"
              ></audio>
            </div>

            <div class="task-box">
              <div class="title">打卡作业：</div>
              <div class="task-content">
                <p>
                  {{ chapterDetailData.content }}
                </p>
                <!-- <p>1、熟读课文</p>
                <p>2、写一个简单做的自我介绍</p>
                <p>3、每个单词抄写5遍，把自己的笔记以高清图片的方式上传哦</p> -->
              </div>
            </div>
            <img
              v-for="(item, index) in chapterDetailData.imgList"
              :key="index + 100"
              class="curriculum-cover"
              :src="item.sourceUrl"
              alt=""
            />
          </div>
        </div>
      </el-main>
    </el-container>
    <Login
      v-if="loginVisible"
      :form="form"
      @getCode="getCode"
      @loginByCode="loginByCode"
      @scanLogin="scanLogin"
      @closeLogin="closeLogin"
    ></Login>
    <!-- <el-dialog
      title=""
      :visible.sync="visible"
      width="632px"
      :before-close="determine"
      :close-on-click-modal="false"
    >
      <div>已看完，去洋葱韩语APP提交作业可解锁下一课课程</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="toReplay" icon="el-icon-refresh-right">重 播</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </div></el-dialog
    > -->
  </div>
</template>

<script>
import { getCode, loginByCode, getUserInfo, loginOut } from "@/api/login";
import {
  getMyCourse,
  getMyCourseChapters,
  chapterDetail,
  getCourseInfo,
  getTeacherCode,
} from "@/api/course";

import Header from "./components/Header";
import Video from "./components/Video";
import Login from "./components/Login";
export default {
  components: {
    Header,
    Video,
    Login,
  },
  props: {},
  data() {
    return {
      form: {
        phone: "",
        code: "",
      },
      // 是否已经登录
      isLogin: this.$cookie.get("token") ? true : false,
      // 用户信息
      userInfo: JSON.parse(this.$cookie.get("userInfo")) || {},
      // 打开登录弹窗
      loginVisible: this.$cookie.get("token") ? false : true,
      // 是否是vip用户
      vipVisible: true,
      // 课程列表
      courseList: [],
      // 当前选择的课程id
      activeCourse: this.$cookie.get("courseId") || null,
      // 课程详情
      courseDetailData: {},
      // 章节列表
      chapterList: [],
      // 当前选择的章节id
      activeChapter: this.$cookie.get("chapterId") || null,
      // 章节详情
      chapterDetailData: {},
      // 视频播放完成
      isPalyComplete: false,
      isNextChapter: false,
      teacherWxCode: "",
    };
  },
  created() {
    this.uploadCreate();
    // if (this.isLogin) {
    //   this.getTeacherCode();
    // }
  },
  methods: {
    //
    async uploadCreate() {
      //登录后并且没有章节id 调用获取课程
      if (this.isLogin && !this.activeChapter) {
        this.getMyCourse();
      } else if (this.isLogin && this.activeChapter) {
        await this.handleCourse(this.activeCourse, this.activeChapter);
        document.getElementById("myVideo").currentTime =
          this.$cookie.get("time");
      }
      window.addEventListener("beforeunload", () => {
        let token = this.$cookie.get("token") ? true : false;
        try {
          let vid = document.getElementById("myVideo").currentTime;
          vid && token
            ? this.$cookie.set("time", vid, 1)
            : this.$cookie.delete("time");
          this.activeCourse && token
            ? this.$cookie.set("courseId", this.activeCourse, 1)
            : this.$cookie.delete("courseId");
          this.activeChapter && token
            ? this.$cookie.set("chapterId", this.activeChapter, 1)
            : this.$cookie.delete("chapterId");
        } catch (error) {
          console.log(error);
        }
      });
    },
    // 登录
    handleLogin() {
      this.loginVisible = true;
    },
    // 获取验证码
    getCode() {
      getCode({
        mobile: Number(this.form.phone), //	手机号
        product: 0, //	产品 0：韩语APP 1落地页 2直播
        terminal: 4, //	终端 1iOS，2安卓 3H5 4网页
        internationalTelephoneCode: 86,
      }).then((res) => {
        if (res.code !== "SUCCESS") return this.$message.error(res.message);
        this.$message.success("正在发送验证码");
      });
    },
    // 退出登录
    logOut() {
      this.$confirm(`确定要退出登录吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message.success("退出登录成功");
          this.activeCourse = null;
          this.activeChapter = null;
          this.isLogin = false;
          this.$cookie.delete("userInfo");
          this.$cookie.delete("token");
          window.location.reload();
          // loginOut().then((res) => {
          //   if (res.code !== "SUCCESS") return this.$message.error(res.message);
          //   this.$message.success("退出登录成功");
          //   this.activeCourse = null;
          //   this.activeChapter = null;
          //   this.isLogin = false;
          //   this.$cookie.delete("userInfo");
          //   this.$cookie.delete("token");
          //   window.location.reload();
          // });
        })
        .catch(() => {});
    },
    // 登录
    loginByCode() {
      loginByCode({
        mobile: this.form.phone, //	手机号
        code: this.form.code, //	验证码
        product: 0, //	产品 0：韩语APP 1、落地页 2、直播
        terminal: 4, //	终端 1iOS，2安卓 3、h5 4、网页端
        internationalTelephoneCode: 86,
      }).then((res) => {
        if (res.code !== "SUCCESS") return this.$message.error(res.message);
        this.$message.success("登录成功");
        this.loginVisible = false;
        this.form = {
          phone: "",
          code: "",
        };
        this.$cookie.set("token", res.data, 1);
        this.isLogin = true;
        this.getUserInfo();
        this.getMyCourse();
      });
    },
    // 扫码登录成功
    scanLogin(data) {
      this.$message.success("登录成功");
      this.$cookie.set("token", data, 1);
      this.isLogin = true;
      this.loginVisible = false;
      this.getUserInfo();
      this.getMyCourse();
    },
    // 关闭登录页面
    closeLogin() {
      this.loginVisible = false;
      this.form = {
        phone: "",
        code: "",
      };
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code !== "SUCCESS") return;
        this.userInfo = res.data;
        this.$cookie.set("userInfo", JSON.stringify(res.data), 1);
      });
    },
    // 获取课程列表
    getMyCourse() {
      getMyCourse().then(async (res) => {
        if (res.code !== "SUCCESS") return this.$message.error(res.message);
        this.courseList = res.data;
        console.log(this.courseList, "this.courseList");
        if (this.courseList.length) {
          this.vipVisible = true;
        } else {
          this.getTeacherCode();
        }
        if (this.courseList.length == 1) {
          this.handleCourse(this.courseList[0].id);
        }
      });
    },
    // 获取老师二维码
    getTeacherCode() {
      getTeacherCode().then((res) => {
        if (res.code !== "SUCCESS") return;
        this.teacherWxCode = res.data;
        this.vipVisible = false;
      });
    },
    // 返回课程列表
    back() {
      this.activeCourse = null;
      this.activeChapter = null;
      this.chapterDetailData = {};
      this.courseDetailData = {};
      this.getMyCourse();
    },
    // 点击课程获取章节列表
    async handleCourse(id, chapterId = "") {
      // await this.getCourseInfo();
      getMyCourseChapters({ courseId: id }).then((res) => {
        if (res.code !== "SUCCESS") return;
        if (res.data && res.data.chapterList.length > 0) {
          this.activeCourse = id;
          this.chapterList = JSON.parse(JSON.stringify(res.data.chapterList));
          let index = this.chapterList.findIndex((item) => !item.unlock);
          this.$set(this.chapterList[index], "unlocking", true); // 第一个未解锁 加个解锁中字段
          console.log(this.chapterList, "11111111");
          if (chapterId) {
            this.chapterDetail(chapterId);
          } else {
            this.chapterDetail(this.chapterList[index].id);
          }
        } else {
          this.$message.error("课程下没有章节");
        }
      });
    },
    // // 获取课程详情
    // getCourseInfo(id) {
    //   return getCourseInfo({ courseId: id }).then((res) => {
    //     if (res.code !== "SUCCESS") return;
    //     this.courseDetailData = res.data;
    //   });
    // },
    // 点击章节
    handleChapter(id) {
      this.chapterDetail(id);
    },
    // 获取章节详情
    chapterDetail(id) {
      return chapterDetail({
        chapterId: id,
        courseId: this.activeCourse,
      }).then(async (res) => {
        if (res.code !== "SUCCESS") return this.$message.error(res.message);
        var audioElement = new Audio(res.data.videoUrl);
        audioElement.addEventListener("loadedmetadata", () => {
          res.data.videoTime = audioElement.duration;
        });
        let audioList = res.data.sourceList.filter(
          (item) => item.sourceType === 1
        );
        audioList.forEach((item) => {
          item.play = false;
        });
        res.data.audioList = audioList;
        res.data.imgList = res.data.sourceList.filter(
          (item) => item.sourceType === 0
        );
        this.isPalyComplete = false;
        this.activeChapter = res.data.id;
        this.chapterDetailData = res.data;
        let video = document.getElementById("myVideo");
        video.currentTime = 0;
        // video.play();
        console.log(this.chapterDetailData, "this.chapterDetailData");
      });
    },
    // 视频播放完成
    palyComplete() {
      let index = this.chapterList.findIndex(
        (item) => item.id === this.activeChapter
      );
      let isUnlock = this.chapterList[index + 1].unlock;
      let unlocking = this.chapterList[index + 1].unlocking;
      console.log(isUnlock, unlocking, "isUnlock1111");
      if (isUnlock || unlocking) {
        this.isNextChapter = true;
        let timeOut = setTimeout(async () => {
          await this.chapterDetail(this.chapterList[index + 1].id);
          this.isNextChapter = false;
          clearTimeout(timeOut);
        }, 2000);
      } else {
        this.isPalyComplete = true;
      }
    },
    // 重播
    toReplay() {
      let video = document.getElementById("myVideo");
      video.currentTime = 0;
      video.play();
      this.isPalyComplete = false;
    },
    // 播发 视频
    videoPlay() {
      let audio = document.querySelectorAll(".myaudio");
      for (let i = 0; i < audio.length; i++) {
        audio[i].pause();
        audio[i].load();
        this.$set(this.chapterDetailData.audioList[i], "play", false);
      }
    },
    // 章节详情 播放音频
    handlePlayAudio(index, flag) {
      let audio = document.querySelectorAll(".myaudio");
      let video = document.getElementById("myVideo");
      if (!flag) {
        for (let i = 0; i < audio.length; i++) {
          if (index === i) {
            audio[index].play();
            this.$set(this.chapterDetailData.audioList[i], "play", true);
            video.pause();
          } else {
            audio[i].pause();
            audio[i].load();
            this.$set(this.chapterDetailData.audioList[i], "play", false);
          }
        }
      } else {
        audio[index].pause();
        audio[index].load();
        this.$set(this.chapterDetailData.audioList[index], "play", false);
      }
    },
    audioEnded(el, index) {
      this.$set(this.chapterDetailData.audioList[index], "play", false);
    },
  },
};
</script>

<style scoped lang="scss">
.home-page {
  height: 100%;
  width: 100%;
}
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 130px);
  min-height: 732px;
  overflow-y: auto;
  background: #131313;
}
.content > .curriculum-name {
  // width: 100%;
  background: #282828;
  .curriculum-content-title {
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 1348px;
    height: 68px;
    font-size: 16px;
    color: white;
    background: #282828;
    .category {
      margin: 0 16px 0 0;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #5a85ff;
      border-radius: 4px;
    }
  }
}

.curriculum-box {
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1348px;
  height: 600px;
}
.curriculum-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 963px;
  background: #333333;
  border-radius: 6px;
  .name {
    padding: 0 0 0 24px;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    color: #ffffff;
  }
}

@media (max-width: 1366px) {
  .curriculum-content-title {
    width: 1274px !important;
  }
  .curriculum-box {
    width: 1274px;
    height: 558.375px;
  }
  .curriculum-video {
    width: 889px;
  }
  .curriculum-details-content {
    width: 1274px !important;
  }
}
.curriculum-list {
  margin: 0 0 0 15px;
  width: 370px;
  background: #333333;
  border-radius: 6px;
  height: 100%;
}
.curriculum-list > .curriculum-list-title {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  border-bottom: 1px solid #000000;
  img {
    margin: 18px 0 0 14px;
    float: left;
    cursor: pointer;
  }
}

.curriculum-list > .curriculum-list-box {
  padding: 16px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 61px);
  overflow-y: auto;
  .curriculum-item {
    margin: 10px 0 0;
    padding: 16px 12px;
    box-sizing: border-box;
    display: flex;
    width: 346px;
    height: 114px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    cursor: pointer;
    .curriculum-img {
      width: 125px;
      height: 82px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .curriculum-info {
      margin-left: 13px;
      padding: 7px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        width: 185px;
        font-size: 16px;
        color: #ffffff;
        overflow: hidden; /*内容超出后隐藏*/
        text-overflow: ellipsis; /* 超出内容显示为省略号 */
        white-space: nowrap; /* 文本不进行换行 */
      }
      .complete {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        color: #ffffff;
      }
    }
  }
}
.chapter-box {
  height: calc(100% - 61px);
  overflow-y: auto;
  .chapter-item {
    padding: 0 87px 0 26px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    cursor: pointer;
    .chapter-info {
      display: flex;
    }
    .chapter-name {
      margin-left: 16px;
      width: 180px;
      font-size: 16px;
      color: #ffffff;
      overflow: hidden; /*内容超出后隐藏*/
      text-overflow: ellipsis; /* 超出内容显示为省略号 */
      white-space: nowrap; /* 文本不进行换行 */
    }
    .active-chapter-name {
      color: #78bbfb !important;
    }
    img {
      height: 24px;
      width: 24px;
    }
    .play {
      height: 20px;
      width: 21px;
    }
  }
}

.curriculum-details {
  width: 100%;
  overflow: auto;
  background: #f6f6f6;
}
.curriculum-details-content {
  padding: 47px 36px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 1348px;
  background: #ffffff;
  .title-name {
    margin: 0 0 25px;
    padding: 0 0 0 4px;
    font-size: 18px;
    color: #333333;
    border-left: 5px solid #5a85ff;
  }
}
.audio-file {
  margin: 12px 0 0;
  padding: 0 44px 0 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 669px;
  height: 66px;
  border-radius: 6px;
  border: 1px solid #87b8ff;
  img {
    display: block;
    width: 40px;
    height: 40px;
  }
  .play {
    margin-left: 16px;
  }
  .audio-tape-reading {
    display: flex;
    img {
      margin-right: 10px;
      display: block;
      width: 44px;
      height: 44px;
    }
    .audio-name {
      display: flex;
      // flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-around;
      font-size: 16px;
      color: #333333;
      .source-name {
        width: 100%;
        font-weight: bold;
      }
    }
    .audio-time {
      font-size: 14px;
      color: #333333;
    }
  }
}
.task-box {
  margin: 20px 0;
  padding: 30px 0 24px 35px;
  box-sizing: border-box;
  max-height: 200px;
  overflow-y: auto;
  background: #f6f9fe;
  border: 1px solid #e0ebff;
  border-radius: 6px;
  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    color: #333333;
  }
  .task-content {
    font-size: 16px;
    line-height: 28px;
    color: #333333;
  }
}
.curriculum-cover {
  width: 100%;
}
::v-deep .el-main {
  padding: 0px;
}
</style>
